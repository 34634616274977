@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.css";
@import "./preflight.css";

*,
*::before,
*::after {
  border-width: 0;
  border-style: solid;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Exo 2", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #2d2d2d;
  --scroll-behavior: auto;
  scroll-behavior: auto;
}

html {
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
}

* {
  box-sizing: border-box;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@layer components {
  .layout-content {
    @apply w-full;
  }
  .layout-block {
    @apply mx-auto max-w-[1440px];
  }
  .action {
    @apply transition-all hover:cursor-pointer hover:opacity-60;
  }
  .form-block {
    @apply p-5 mb-5 rounded-lg border border-solid border-gray_e7;
  }

  .form-error-block {
    @apply p-5 mb-11 rounded-lg border border-solid border-error;
  }

  .form-focus-block {
    @apply !border border-solid !border-primary;
  }

  .form-error-text {
    @apply text-error text-body;
  }

  .input-error-block {
    @apply mb-5 border-error relative;
  }

  .input-pri {
    @apply w-full appearance-none rounded border  px-4 py-2 leading-tight text-dark1 border-gray_e7 focus:outline-none;
  }
  .input-form {
    @apply w-full appearance-none rounded border border-gray_e7/90  px-4 py-2 leading-tight text-dark1 focus:outline-none;
  }
  .input-pri-error {
    @apply w-full appearance-none rounded border border-red-500 border-opacity-90 bg-red-500 bg-opacity-20 px-4 py-2 leading-tight text-dark1 focus:outline-none;
  }
  .input-white {
    @apply mb-6 w-full appearance-none rounded border bg-primary px-4 py-2 leading-tight  focus:outline-none;
  }
  .label-form {
    @apply mb-3 flex text-sm tracking-wide text-dark1 font-semibold;
  }

  .input-pri-sm {
    @apply w-20 appearance-none rounded border border-gray_e7  px-4 py-2 leading-tight text-dark1 focus:outline-none;
  }

  .input-pri-sm-error {
    @apply w-20 appearance-none rounded border border-red-500 border-opacity-90 bg-red-500 bg-opacity-20 px-4 py-2 leading-tight text-primary focus:outline-none;
  }

  .disable {
    @apply before:content-[""] before:absolute before:w-full before:h-full before:z-10;
  }

  .btn-pri {
    @apply btn-pri-base;
    @apply py-2;
  }
  .btn-sec {
    @apply btn-sec-base;
    @apply py-2;
  }
  .btn-bgv2 {
    @apply bg-primary py-2 hover:bg-primary/90;
    @apply btn;
  }
  .btn {
    @apply inline-block rounded-full text-xs hover:bg-opacity-90  cursor-pointer;
  }

  .btn-pri-base {
    @apply bg-primary font-semibold text-white;
    @apply btn;
  }
  .btn-sec-base {
    @apply bg-gray2 text-accent;
    @apply btn;
  }
  .claim-step-card {
    @apply border border-[#e0e1e1] rounded-2xl p-4 sm:p-8;
  }
  .credential-card {
    @apply relative bg-credentialBg rounded-lg py-6 px-8 flex flex-col sm:flex-row sm:items-center sm:justify-between border transition-all  border-transparent hover:border-[#3C86FB];
  }
}

a:hover {
  color: initial;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #e0e1e1 #3c86fb;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e0e1e1;
  border-radius: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3c86fb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.ant-table {
  border-radius: 8px !important;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.ant-table-body {
  overflow-y: initial !important;
}

.ant-table-column-sorters {
  justify-content: flex-start;
}

.column-center .ant-table-column-sorters {
  justify-content: center;
}
.column-center.ant-table-cell {
  text-align: center !important;
}

.ant-table-column-has-sorters:hover {
  background: #ffffff !important;
}

.ant-table-column-title {
  flex: 0 0 auto;
}

.ant-table-cell-row-hover {
  background: #ffffff !important;
}

.ant-table-thead > tr > th {
  background: #ffffff;
}

.ant-table-thead .ant-table-cell.ant-table-column-sort {
  background: #ffffff;
}

.ant-table-thead .ant-table-cell {
  padding: 26px 16px;
}

.ant-table-cell::before {
  display: none;
}

.ant-table-tbody .ant-table-column-sort {
  background: #ffffff;
}

.ant-table-placeholder {
  display: none;
}

.ant-btn-primary {
  background: #1890ff;
}

.ant-picker-month-btn,
.ant-picker-year-btn,
.ant-picker-decade-btn {
  font-family: "Exo 2";
}

.ant-popover-inner {
  box-shadow: 15px 4px 100px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;
}

.ant-modal-content {
  border-radius: 8px;
}

.custom-confirm .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
}

.ant-modal-content {
  width: 438px;
}

.ant-modal-confirm-btns .ant-btn {
  font-weight: bold;
}

.ant-table-container::after,
.ant-table-container::before {
  display: none !important;
}

@keyframes loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-880px);
  }
}

@keyframes loopInvestor {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1314px);
  }
}

.word-rotation {
  height: 106px;
  position: relative;
  overflow: hidden;
  --offset: -30px;
  margin-top: var(--offset);
}

@media (max-width: 640px) {
  .word-rotation {
    --offset: -10px;
  }
}

.word-rotation .word {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 100px;
  padding-bottom: var(--offset);
  line-height: 1.2;

  background-size: 100% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
}

.word-rotation .word::selection {
  -webkit-text-fill-color: #ffffff;
}

.word-rotation .word[data-show] {
  transform: translateY(-100%);
  transition: 0.5s transform ease-in-out;
}

.word-rotation .word[data-up] {
  transform: translateY(-200%);
  transition: 0.5s transform ease-in-out;
}

.word-rotation .word:nth-child(1) {
  background-image: linear-gradient(45deg, #0ecffe 50%, #07a6f1);
}

.word-rotation .word:nth-child(2) {
  background-image: linear-gradient(45deg, #18e198 50%, #0ec15d);
}

.word-rotation .word:nth-child(3) {
  background-image: linear-gradient(45deg, #8a7cfb 50%, #633e9c);
}

.word-rotation .word:nth-child(4) {
  background-image: linear-gradient(45deg, #fa7671 50%, #f45f7f);
}

.mantine-Pagination-item {
  border: none;
}

.mantine-Pagination-item[data-active] {
  background: transparent;
  color: #3c86fb;
}

.border-gradient {
  position: relative;
}

.border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 16px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    rgba(135, 181, 255, 1) 0%,
    rgba(173, 181, 255, 0.15) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.mantine-SegmentedControl-controlActive label {
  color: #3c86fb !important;
}

#deshilling-button iframe {
  height: 460px !important;
}
.custom-flip {
  background: transparent !important;
  box-shadow: none !important;
  border: none;
}

.custom-flip > div {
  margin-bottom: 15px;
}

.custom-flip .mantine-ScrollArea-root {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.mantine-Carousel-root,
.mantine-Carousel-viewport,
.mantine-Carousel-container,
.mantine-Carousel-slide {
  height: 100%;
}

.mantine-Carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.un-stake-table tbody td {
  border-top: none !important;
  padding: 0.13rem 0.625rem !important;
}

.kat-reward-table .ant-table-tbody .ant-table-cell {
  border: none !important;
}

.kat-reward-table .ant-table-tbody .ant-table-row:nth-child(2n + 1) {
  background: #fafafa;
}

.kat-reward-table .ant-table-tbody .ant-table-cell-row-hover {
  background: inherit !important;
}

.kat-reward-table .ant-table-thead .ant-table-cell {
  font-weight: 600;
}

.mantine-Tabs-tab[data-active="true"] {
  color: #3c86fb !important;
  font-weight: 600 !important;
}
